// CircleGraph.jsx
import React from 'react';

// CircleGraph renders an SVG pie chart based on the rewards array.
// Each reward object should have an 'item' and a 'rate' property.
// The component calculates the relative proportion of each rate and draws a slice.
const CircleGraph = ({ rewards }) => {
  // Compute the total rate to determine relative proportions.
  const totalRate = rewards.reduce((sum, reward) => sum + reward.rate, 0);
  let cumulativeAngle = 0;

  // Map each reward to an arc slice with its corresponding SVG path data.
  const arcs = rewards.map((reward, index) => {
    // Determine the angle (in degrees) for this reward slice.
    const sliceAngle = (reward.rate / totalRate) * 360;
    const startAngle = cumulativeAngle;
    cumulativeAngle += sliceAngle;
    const endAngle = cumulativeAngle;
    const radius = 50; // Radius of the pie chart.
    // Convert polar coordinates to cartesian for the arc path.
    const start = polarToCartesian(50, 50, radius, endAngle);
    const end = polarToCartesian(50, 50, radius, startAngle);
    // Determine if the arc is greater than 180 degrees.
    const largeArcFlag = sliceAngle > 180 ? 1 : 0;
    // Create the SVG path command for the arc.
    const pathData = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      'L', 50, 50,
      'Z'
    ].join(' ');
    // Generate a hue-based color for the slice.
    const color = `hsl(${(index * 137.5) % 360}, 50%, 50%)`;
    return { pathData, color, reward };
  });

  // Helper function to convert polar coordinates to cartesian coordinates.
  function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  }

  return (
    <svg width="100" height="100">
      {arcs.map((arc, idx) => (
        // Each slice is a path with a title showing the item and its percentage.
        <path key={idx} d={arc.pathData} fill={arc.color}>
          <title>
            {arc.reward.item}: {((arc.reward.rate / totalRate) * 100).toFixed(2)}%
          </title>
        </path>
      ))}
    </svg>
  );
};

export default CircleGraph;
